import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Container, Image, Text } from '_components/atoms'
import { TextJSON } from '_components/molecules'
import { rawToJSON } from '_utils/functions'
import { setCssVar } from '_utils/set-css-var'

import styles from './styles.module.css'

const cssVarNamespace = 'section7'

export const Section7 = ({ data, config }) => {
  const wrapperVars = setCssVar(
    {
      bg: config.backgroundColor,
    },
    cssVarNamespace
  )

  const quoteVars = setCssVar(
    {
      'quote-text-color': config.quoteColor,
      'quote-sign-color': config.quoteDotColor,
    },
    cssVarNamespace
  )

  return (
    <div className={styles.wrapper} {...wrapperVars}>
      <Container className={styles.container}>
        <Fade distance="10%" top>
          <TextJSON className={styles.quote} {...quoteVars} formatted>
            {rawToJSON(data.section7Quote.raw)}
          </TextJSON>
        </Fade>

        <div className={styles.quoteFooter}>
          <Fade distance="20%" bottom>
            <div className={styles.quoteAuthorWrapper}>
              <div className={styles.quoteAvatar}>
                <Image fluid={data.section7Avatar.fluid} alt={data.section7Name} />
              </div>
              <div className={styles.quoteAuthor}>
                <Text size="20" bold color="white">
                  {data.section7Name}
                </Text>
                <Text size="R16" color="white">
                  {data.section7Role}
                </Text>
              </div>
            </div>
            <a
              href={data.section7Link}
              target="_blank"
              rel="noreferrer"
              className={styles.quoteImageLink}
            >
              <Image fluid={data.section7Image.fluid} alt={data.section7Name} />
            </a>
          </Fade>
        </div>
      </Container>
    </div>
  )
}

Section7.propTypes = {
  config: PropTypes.shape({
    backgroundColor: PropTypes.string,
    nameColor: PropTypes.string,
    roleColor: PropTypes.string,
    quoteColor: PropTypes.string,
    quoteDotColor: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    section7Quote: PropTypes.shape({
      raw: PropTypes.string,
    }),
    section7Name: PropTypes.string,
    section7Role: PropTypes.string,
    section7Link: PropTypes.string,
    section7Image: PropTypes.shape({
      description: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
    section7Avatar: PropTypes.shape({
      description: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }).isRequired,
}
