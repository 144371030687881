import React from 'react'
import PropTypes from 'prop-types'

import { Image } from '_components/atoms'
import { GenericContentSection, TextJSON } from '_components/molecules'
import { rawToJSON } from '_utils/functions'

import styles from './styles.module.css'

export const Section2 = ({ data, config }) => {
  return (
    <GenericContentSection
      sectionId="section2"
      backgroundColor={config.backgroundColor}
      titleLastCharColor={config.titleDotColor}
      title={data.section2Title}
      titleColor={config.titleColor}
      text={
        <TextJSON className={styles.text} size="22" formatted>
          {rawToJSON(data.section2Text.raw)}
        </TextJSON>
      }
    >
      <div className={styles.image}>
        <Image fluid={data.section2Image.fluid} alt={data.section2Image.description} />
      </div>
    </GenericContentSection>
  )
}

Section2.propTypes = {
  config: PropTypes.shape({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    titleDotColor: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    section2Title: PropTypes.string,
    section2Text: PropTypes.shape({
      raw: PropTypes.string,
    }),
    section2Image: PropTypes.shape({
      description: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }).isRequired,
}
