import PropTypes from 'prop-types'

const gatsbyImagePropType = PropTypes.shape({
  description: PropTypes.string,
  fluid: PropTypes.shape({
    aspectRatio: PropTypes.number,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcWebp: PropTypes.string,
    srcSetWebp: PropTypes.string,
    sizes: PropTypes.string,
  }),
})

// Generated with https://transform.tools/json-to-proptypes
// using the query json output from the Gatsby GraphQL Playground
export const pagePropTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      configuration: PropTypes.shape({
        internal: PropTypes.shape({
          content: PropTypes.string,
        }),
      }),
      seo: PropTypes.shape({
        pageTitle: PropTypes.string,
        pageDescription: PropTypes.string,
        thumbnail: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
            fileName: PropTypes.string,
            contentType: PropTypes.string,
          }),
        }),
      }),
    }),
    section1: PropTypes.shape({
      section1Title: PropTypes.string,
      section1Logo: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
      section1Image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
    }),
    section2: PropTypes.shape({
      section2Title: PropTypes.string,
      section2Text: PropTypes.shape({
        raw: PropTypes.string,
      }),
      section2Image: PropTypes.shape({
        id: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
        description: PropTypes.string,
      }),
    }),
    section3: PropTypes.shape({
      section3Title: PropTypes.string,
      section3Icon1: PropTypes.shape({
        id: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
      section3Text1: PropTypes.string,
      section3Text2: PropTypes.string,
      section3Icon2: PropTypes.shape({
        id: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
    }),
    section4: PropTypes.shape({
      section4Title: PropTypes.string,
      section4Text: PropTypes.shape({
        raw: PropTypes.string,
      }),
      section4ButtonLabel: PropTypes.string,
      section4ButtonType: PropTypes.arrayOf(PropTypes.string),
      section4ButtonHref: PropTypes.string,
    }),
    section5: PropTypes.shape({
      section5Title: PropTypes.string,
      section5Text: PropTypes.shape({
        raw: PropTypes.string,
      }),
      section5Image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
    }),
    section6: PropTypes.shape({
      section6Title: PropTypes.string,
      section6Text: PropTypes.shape({
        raw: PropTypes.string,
      }),
      section6Image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
      section6ButtonLabel: PropTypes.string,
      section6ButtonType: PropTypes.arrayOf(PropTypes.string),
      section6ButtonHref: PropTypes.string,
    }),
    section7: PropTypes.shape({
      section7Quote: PropTypes.shape({
        raw: PropTypes.string,
      }),
      section7Name: PropTypes.string,
      section7Role: PropTypes.string,
      section7Link: PropTypes.string,
      section7Image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
      section7Avatar: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
    }),
    section8: PropTypes.shape({
      section8Title: PropTypes.string,
      section8ButtonLabel: PropTypes.string,
      section8ButtonType: PropTypes.arrayOf(PropTypes.string),
      section8ButtonHref: PropTypes.string,
      section8Link1: PropTypes.string,
      section8Image1: gatsbyImagePropType,
      section8Link2: PropTypes.string,
      section8Image2: gatsbyImagePropType,
    }),
  }),
  extensions: PropTypes.shape({}),
}
