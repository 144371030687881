import React from 'react'
import { graphql } from 'gatsby'
import config from 'react-reveal/globals'

import { CookieConsentBar } from '_molecules'
import { Navbar, Footer, SEO } from '_organisms'
import {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
} from '_organisms/use-case-portfolio'

import { pagePropTypes } from './proptypes'

const UseCasePortfolio = ({ data }) => {
  config({ ssrFadeout: true })
  const pageConfig = JSON.parse(data.page.configuration.internal.content)
  const { seo } = data.page

  return (
    <>
      <SEO
        title={seo.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={seo.pageDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={seo.thumbnail ? seo.thumbnail.file.url : null}
      />
      <Navbar />
      <Section1 data={data.section1} config={pageConfig.section1} />
      <Section2 data={data.section2} config={pageConfig.section2} />
      <Section3 data={data.section3} config={pageConfig.section3} />
      <Section4 data={data.section4} config={pageConfig.section4} />
      <Section5 data={data.section5} config={pageConfig.section5} />
      <Section6 data={data.section6} config={pageConfig.section6} />
      <Section7 data={data.section7} config={pageConfig.section7} />
      <Section8 data={data.section8} config={pageConfig.section8} />
      <CookieConsentBar />
      <Footer />
    </>
  )
}

UseCasePortfolio.propTypes = pagePropTypes

export const query = graphql`
  query GetUseCasePortfolioPage($slug: String, $locale: String) {
    page: contentfulUseCasePortfolioTemplate(
      fields: { localeKey: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      configuration {
        internal {
          content
        }
      }
      seo {
        pageTitle
        pageDescription
        thumbnail {
          file {
            url
          }
        }
      }
    }
    section1: contentfulUseCasePortfolioTemplate(
      fields: { localeKey: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      section1Title
      section1Logo {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      section1Image {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    section2: contentfulUseCasePortfolioTemplate(
      fields: { localeKey: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      section2Title
      section2Text {
        raw
      }
      section2Image {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    section3: contentfulUseCasePortfolioTemplate(
      fields: { localeKey: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      section3Title
      section3Icon1 {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      section3Text1
      section3Text2
      section3Icon2 {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    section4: contentfulUseCasePortfolioTemplate(
      fields: { localeKey: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      section4Title
      section4Text {
        raw
      }
      section4ButtonLabel
      section4ButtonType
      section4ButtonHref
      section4Image {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    section5: contentfulUseCasePortfolioTemplate(
      fields: { localeKey: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      section5Title
      section5Text {
        raw
      }
      section5Image {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    section6: contentfulUseCasePortfolioTemplate(
      fields: { localeKey: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      section6Title
      section6Text {
        raw
      }
      section6Image {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      section6ButtonLabel
      section6ButtonType
      section6ButtonHref
    }
    section7: contentfulUseCasePortfolioTemplate(
      fields: { localeKey: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      section7Quote {
        raw
      }
      section7Name
      section7Role
      section7Link
      section7Image {
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      section7Avatar {
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    section8: contentfulUseCasePortfolioTemplate(
      fields: { localeKey: { eq: $locale } }
      slug: { eq: $slug }
    ) {
      section8Title
      section8ButtonLabel
      section8ButtonType
      section8ButtonHref
      section8Link1
      section8Image1 {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      section8Link2
      section8Image2 {
        description
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default UseCasePortfolio
