import React from 'react'
import PropTypes from 'prop-types'

import { Image } from '_components/atoms'
import { GenericContentSection, TextJSON } from '_components/molecules'
import { rawToJSON } from '_utils/functions'
import { setCssVar } from '_utils/set-css-var'
import { useMobileView } from '_hooks'

import styles from './styles.module.css'

export const Section5 = ({ data, config }) => {
  const wrapperStyles = setCssVar({
    'section5-skew-bg': config.backgroundColor,
  })
  const textStyles = setCssVar({
    'section5-text-color': config.textColor,
  })
  const { isMobile } = useMobileView()

  return (
    <div className={styles.wrapper} {...wrapperStyles}>
      <GenericContentSection
        containerClassname={styles.container}
        direction={isMobile ? 'row' : 'row-reverse'}
        backgroundColor="rgba(255, 255, 255, 0)"
        titleLastCharColor={config.titleDotColor}
        title={data.section5Title}
        titleColor={config.titleColor}
        text={
          <TextJSON className={styles.text} {...textStyles} size="22" formatted>
            {rawToJSON(data.section5Text.raw)}
          </TextJSON>
        }
        textColor={config.textColor}
      >
        <div className={styles.image}>
          <Image fluid={data.section5Image.fluid} alt={data.section5Image.description} />
        </div>
      </GenericContentSection>
    </div>
  )
}

Section5.propTypes = {
  config: PropTypes.shape({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    titleDotColor: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    section5Title: PropTypes.string,
    section5Text: PropTypes.shape({
      raw: PropTypes.string,
    }),
    section5Image: PropTypes.shape({
      description: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }).isRequired,
}
