import React from 'react'
import PropTypes from 'prop-types'

import { Image } from '_components/atoms'
import { GenericContentSection, TextJSON } from '_components/molecules'
import { rawToJSON } from '_utils/functions'

import styles from './styles.module.css'

export const Section4 = ({ data, config }) => {
  return (
    <GenericContentSection
      direction="row"
      backgroundColor={config.backgroundColor}
      titleLastCharColor={config.titleDotColor}
      title={data.section4Title}
      titleColor={config.titleColor}
      text={
        <TextJSON className={styles.text} size="22" formatted>
          {rawToJSON(data.section4Text.raw)}
        </TextJSON>
      }
      buttonLabel={data.section4ButtonLabel}
      buttonHref={data.section4ButtonHref}
      buttonType={data.section4ButtonType}
      containerClassname={styles.container}
    >
      <div className={styles.image}>
        <Image fluid={data.section4Image.fluid} alt={data.section4Image.description} />
      </div>
    </GenericContentSection>
  )
}

Section4.propTypes = {
  config: PropTypes.shape({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    titleDotColor: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    section4Title: PropTypes.string,
    section4Text: PropTypes.shape({
      raw: PropTypes.string,
    }),
    section4ButtonLabel: PropTypes.string,
    section4ButtonType: PropTypes.arrayOf(PropTypes.string),
    section4ButtonHref: PropTypes.string,
    section4Image: PropTypes.shape({
      description: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }).isRequired,
}
