import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Container, Heading, Image, LastColoredCharText, Text } from '_components/atoms'
import { setCssVar } from '_utils/set-css-var'

import styles from './styles.module.css'

export const Section3 = ({ data, config }) => {
  return (
    <div
      className={styles.wrapper}
      {...setCssVar({ 'section3-wrapper-bg': config.backgroundColor })}
    >
      <Container className={styles.container}>
        <Fade distance="10%" top>
          <Heading
            type="h4"
            className={styles.title}
            bold
            {...setCssVar({ 'section3-title-color': config.titleColor })}
          >
            <LastColoredCharText content={data.section3Title} color={config.titleDotColor} />
          </Heading>
        </Fade>
        <div className={styles.row}>
          <Fade distance="20%" top>
            <div className={styles.column}>
              <Image
                fluid={data.section3Icon1.fluid}
                alt={data.section3Icon1.description}
                className={styles.icon}
              />
              <Text
                size="22"
                className={styles.text}
                {...setCssVar({ 'section3-text-color': config.textColor })}
              >
                {data.section3Text1}
              </Text>
            </div>
          </Fade>
          <Fade distance="20%" top>
            <div className={styles.column}>
              <Image
                fluid={data.section3Icon2.fluid}
                alt={data.section3Icon2.description}
                className={styles.icon}
              />
              <Text
                size="22"
                className={styles.text}
                {...setCssVar({ 'section3-text-color': config.textColor })}
              >
                {data.section3Text2}
              </Text>
            </div>
          </Fade>
        </div>
      </Container>
    </div>
  )
}

Section3.propTypes = {
  config: PropTypes.shape({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    titleDotColor: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    section3Title: PropTypes.string,
    section3Text1: PropTypes.string,
    section3Text2: PropTypes.string,
    section3Icon1: PropTypes.shape({
      description: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
    section3Icon2: PropTypes.shape({
      description: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }).isRequired,
}
