import React from 'react'
import PropTypes from 'prop-types'

import { HeroSection } from '_organisms'
import { Image } from '_atoms'

import styles from './styles.module.css'

export const Section1 = ({ data, config }) => {
  const topLogo = (
    <>
      <div className={styles.logo}>
        <Image fluid={data.section1Logo.fluid} alt={data.section1Logo.description} />
      </div>
    </>
  )
  const image = (
    <>
      <Image
        className={styles.image}
        fluid={data.section1Image.fluid}
        alt={data.section1Image.description}
      />
    </>
  )

  return (
    <>
      <HeroSection
        title={data.section1Title}
        image={image}
        topContent={topLogo}
        scrollDownElementId="section2"
        arrowColor={config.arrowColor}
        titleColor={config.titleColor}
        backgroundColor={config.backgroundColor}
      />
    </>
  )
}

Section1.propTypes = {
  config: PropTypes.shape({
    arrowColor: PropTypes.string,
    titleColor: PropTypes.string,
    backgroundColor: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape().isRequired,
}
