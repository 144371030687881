import React from 'react'
import PropTypes from 'prop-types'

import { Image } from '_components/atoms'
import { GenericContentSection } from '_components/molecules'
import { useMobileView } from '_hooks'

import styles from './styles.module.css'

export const Section8 = ({ data, config }) => {
  const { isMobile } = useMobileView()

  return (
    <GenericContentSection
      direction={isMobile ? 'row' : 'row-reverse'}
      backgroundColor={config.backgroundColor}
      titleLastCharColor={config.titleDotColor}
      title={data.section8Title}
      titleColor={config.titleColor}
      buttonLabel={data.section8ButtonLabel}
      buttonHref={data.section8ButtonHref}
      buttonType={data.section8ButtonType}
    >
      <div className={styles.imagesRow}>
        <a className={styles.images} href={data.section8Link1} rel="noreferrer" target="_blank">
          <Image
            className={styles.image}
            fluid={data.section8Image1.fluid}
            alt={data.section8Image1.description}
          />
        </a>

        <a className={styles.images} href={data.section8Link2} rel="noreferrer" target="_blank">
          <Image
            className={styles.image}
            fluid={data.section8Image2.fluid}
            alt={data.section8Image2.description}
          />
        </a>
      </div>
    </GenericContentSection>
  )
}

Section8.propTypes = {
  config: PropTypes.shape({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    titleDotColor: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    section8Title: PropTypes.string,
    section8ButtonLabel: PropTypes.string,
    section8ButtonType: PropTypes.arrayOf(PropTypes.string),
    section8ButtonHref: PropTypes.string,
    section8Link1: PropTypes.string,
    section8Image1: PropTypes.shape({
      description: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
    section8Link2: PropTypes.string,
    section8Image2: PropTypes.shape({
      description: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }).isRequired,
}
